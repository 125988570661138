import { useContext, useEffect, useState } from "react"
import "./admin_page.css"
import ProductAddCard from "../../components/Product_add_card/product_reduct_card"
import { createProduct } from "../../http/product_api"
import { Context } from "../.."
import ProductReductCard from "../../components/Product_reduct_card/product_reduct_card"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { ADMIN_ROUTE, AUTH_ROUTE, MAIN_ROUTE } from "../../consts/utils"
import { ReactComponent as Arrow} from "../../assets/ActivePagArrow.svg"

const AdminPage = () => {

    const {products} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()

    const [addProduct, setAddProduct] = useState({
        title: "",
        description: "", 
        price: "",
        stepPrice: "",
    })

    const[preview, setPreview] = useState(null)
    // const[error, setError] = useState({
    //     title: false,
    //     description: false, 
    //     price: false,
    //     stepPrice: false,
    //     img: false,
    // })

    useEffect(() => {
        if(location.pathname === ADMIN_ROUTE){
            if(!products.Employ){
                navigate(AUTH_ROUTE)
            }
        }
       
    }, [products.Employ, location.pathname, navigate])

    function AddProduct(){
        const formData = new FormData()

        formData.set("image", preview)
        formData.set("title", addProduct.title)
        formData.set("description", addProduct.description)
        formData.set("price", addProduct.price)
        formData.set("stepPrice", addProduct.stepPrice)
        createProduct(formData).catch((e) => {console.log(e)})
        
    }

    return(
        <div className="admin_wrapper">
            <Link to={MAIN_ROUTE}><Arrow style={{transform: "rotate(180deg)"}}/> На главную</Link>
            <div className="admin_container">
               <div className="admin_add_wrapper">
                    <ProductAddCard product={addProduct} setImage={preview} setter={setPreview} setterInfo={setAddProduct} functionName={AddProduct} name={"добавить"}/>
               </div>
               <div className="admin_reduct_wrapper">
                    {products.Products.map((i, index) => {
                        return(
                            <ProductReductCard key={i.title + index} product={i}/>
                        )   
                    })}
               </div>
            </div>
        </div>
    )
}

export default AdminPage