import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ProductStore from './stores/product_store';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_reCaptcha}
      >
        <Context.Provider value={
          {
            products: new ProductStore(),
          }
          }>
          <App />
        </Context.Provider>
      </GoogleReCaptchaProvider>
  </React.StrictMode>
);