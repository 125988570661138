import {makeAutoObservable} from "mobx";

export default class ProductStore {
    constructor() {
        this._products = []
        this._cart = []
        this._isEmploy = false
        makeAutoObservable(this)
    }

    setProducts(products) {
        this._products = products
    }

    setCart(product) {
        this._cart = product
    }

    setEmploy(employ){
        this._isEmploy = employ
    }

    get Employ(){
        return this._isEmploy
    }

    get Products() {
        return this._products
    }
    
    get Cart() {
        return this._cart
    }
    
}