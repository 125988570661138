import {$authHost, $host} from "./index";
import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";

const cookie = new Cookies(null,  { path: '/' })

export const login = async (formData) => {
    const {data} = await $host.post('api/auth/login', 
    {
        email: formData.mail,
        password: formData.password
    })
    cookie.set('token', data.token)
    return jwtDecode(data.token)
}

export const check = async () => {
    const {data} = await $authHost.get('api/auth/auth' )
    cookie.set('token', data.token)
    return jwtDecode(data.token)
}