import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRouter from './consts/AppRouter';
import { useContext, useEffect } from 'react';
import { fetchProduct } from './http/product_api';
import { Context } from '.';
import { check } from './http/user_api';

function App() {
  
  const {products} = useContext(Context)

  useEffect(() => {
    check().then(() => {
      products.setEmploy(true)
    }).catch((e) => {console.log(e)})
    fetchProduct().then(data => {
      products.setProducts(data)
    }).catch((e) => {console.log(e)})
  }, [products])

  return (
    <div className="App">
        <BrowserRouter>
          <AppRouter/>
        </BrowserRouter>
    </div>
  );
}

export default App;
