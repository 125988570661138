import "./modals.css"

const { useEffect, useMemo, useState } = require("react");
const { createPortal } = require("react-dom");


const modalRootElement = document.querySelector("#cart");

const CartModals = (props) => {
    const [show, setShow] = useState(props.open)
    const element = useMemo(() => {
        const element = document.createElement("div")
        element.classList.add("modal_wrapper");
        return element
    }, [])
   
    useEffect(() => {
        
        modalRootElement.appendChild(element)
        setShow(props.open)
        return() => {
            modalRootElement.removeChild(element)
        }
        
    }, [element, props.open])

    useEffect(() => {
        if(show){
            element.classList.add("show");
        }else{
            element.classList.remove("show");
        }
    }, [show, element.classList])


    if(props.open){
        return createPortal(props.children, element)
    }else{
        return null
    }

}
    

export default CartModals