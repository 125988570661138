import admin_page from "../pages/admin_page/admin_page"
import AuthPage from "../pages/auth_page/auth_page"
import UserPage from "../pages/users_page/user_page"
import { ADMIN_ROUTE, AUTH_ROUTE, MAIN_ROUTE } from "./utils"


export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        component: UserPage,
    },
    {
        path: AUTH_ROUTE,
        component: AuthPage,
    }
]

export const privateRoutes = [
    {
        path: ADMIN_ROUTE,
        component: admin_page,
    }
]