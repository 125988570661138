import "./footer.css"
import { Link } from "react-router-dom";
import { ADMIN_ROUTE} from "../../consts/utils";



const Footer = () => {


    return(
        <div className="footer_wrapper">
            <p>ИП Садиков А. М.</p>
            <div className="footer_info_wrapper">
                <p> ИНН: 550713921225<br></br>
                    ОГРНИП: 304550713400409
                </p>
                
                <div className="linksTo">
                    <a href="tel:+73812727233">Тел.: 8 (3812) 72-72-33</a>
                    <a href="tel:+79136576679">Тел.: 8 (913) 657-66-79</a>
                    <a href="mailto: posyda2-ras@mail.ru">Почта: posyda2-ras@mail.ru</a>
                </div>
            </div>
            <Link to={ADMIN_ROUTE}>Вход для сотрудника</Link>
        </div>
    )
}

export default Footer