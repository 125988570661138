import {$authHost, $host} from "./index";

export const createProduct = async (product) => {
    const {data} = await $authHost.post('api/product/', product)
    return data
}

export const updateProduct = async (product) => {
    const {data} = await $authHost.put('api/product/', product)
    return data
}

export const fetchProduct = async () => {
    const {data} = await $host.get('api/product/')
    return data
}

export const deleteProduct = async (id) => {
    const {data} = await $authHost.delete('api/product?id=' + id)
    return data
}

export const sendCart = async (formData) => {
    const {data} = await $host.post('api/send', formData)
    return data
}