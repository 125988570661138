import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "./user_page.css"
import "./user_page_media.css"
import { ReactComponent as Logo} from "../../assets/Logo.svg"
import { ReactComponent as Cart} from "../../assets/Cart.svg"
import { ReactComponent as Contacts} from "../../assets/Phone.svg"

import { ReactComponent as Isometric} from "../../assets/isometric.svg"
import { ReactComponent as Down} from "../../assets/Down.svg"
import { ReactComponent as Tops} from "../../assets/Top.svg"
import { ReactComponent as Flag} from "../../assets/flag.svg"
import { ReactComponent as Second} from "../../assets/second_down.svg"
import { ReactComponent as SecondRight} from "../../assets/second_right.svg"
import { ReactComponent as BottomIsometric} from "../../assets/bottom_isometric.svg"
import { ReactComponent as Warning} from "../../assets/Warning.svg"
import { ReactComponent as EmptyCart} from "../../assets/EmptyCart.svg"
import { ReactComponent as Cross} from "../../assets/Cross.svg"
import { ReactComponent as EndArrow} from "../../assets/StopPagArrow.svg"
import { ReactComponent as Arrow} from "../../assets/ActivePagArrow.svg"
import Footer from "../../components/footer/footer";
import ProductCard from "../../components/Product_card/product_card";
import CartModal from "../../modals/Cart";
import { Context } from "../..";
import ContactModals from "../../modals/Contacts";

import { InputMask } from '@react-input/mask';
import { sendCart } from "../../http/product_api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";




const UserPage = () => {

    const {products} = useContext(Context)
    const panel = useRef(null)
    const location = useLocation()
    

    const [cartOpen, setOpenCart] = useState(false)
    const [contactOpen, setContactOpen] = useState(false)

    const [currentPageArray, setCurrentPageArray] = useState([])
    const [pageCurrent, setPageCurrent] = useState(1)
    const [pagesCount, setPagesCount] = useState([1])

    const [sending, setSending] = useState(false)
    const [load, setLoad] = useState(false)
    const [success, setSuccess] = useState(false)

    const [openDelivery, setOpenDelivery] = useState(false)

    const [size, setSize] = useState(window.innerWidth)

    const [error, setError] = useState({
        name: false,
        phone: false,
        description: false,
    })

    const [formParams, setFormParams] = useState({
        name: "",
        phone: "",
        description: "",
    })
    
    const [summ, setSumm] = useState(0)

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }
    
        const token = await executeRecaptcha('submit');
        return token
        // Do whatever you want with the token
      }, [executeRecaptcha]);

    function throttle(size, ms = 1000) {
        let shouldWait = false
        return(...args) => {
            if(shouldWait) return
            size(...args)
            shouldWait = true
            setTimeout(()=>{
                shouldWait = false
            }, ms)
        }
    }

    const updateSize = throttle(data => {
        setSize(data)
    })

    useEffect(() => {
        window.addEventListener("resize", () => {
            updateSize(window.innerWidth)
        })
    }, [updateSize])

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
      }

    useEffect(() =>{
        if(size >= 1270){
            let Array = []
            for(let i = 0; i < Math.ceil(products.Products.length / 12); i++){
                Array.push(i + 1)
            }
            setPagesCount(Array) 
            setCurrentPageArray(paginate(products.Products, 12, pageCurrent))
        }else if(size < 1270 && size >= 1000){
            let Array = []
            for(let i = 0; i < Math.ceil(products.Products.length / 9); i++){
                Array.push(i + 1)
            }
            setPagesCount(Array) 
            setCurrentPageArray(paginate(products.Products, 9, pageCurrent))
        }else if(size < 1000 && size >= 727){
            let Array = []
            for(let i = 0; i < Math.ceil(products.Products.length / 6); i++){
                Array.push(i + 1)
            }
            setPagesCount(Array) 
            setCurrentPageArray(paginate(products.Products, 6, pageCurrent))
        }else{
            let Array = []
            for(let i = 0; i < Math.ceil(products.Products.length / 3); i++){
                Array.push(i + 1)
            }
            setPagesCount(Array) 
            setCurrentPageArray(paginate(products.Products, 3, pageCurrent))
        }
        
    }, [products.Products, size, pageCurrent])


    function OpenCart(){
        setOpenCart(true)
        let sum = 0
        for (let i = 0; i < products.Cart.length; i++) {
            sum += products.Cart[i].price * products.Cart[i].count
        }

        setSumm(sum)


    }

    function removeFromCart(e){

        
        const prodChanger = products.Cart

        if (prodChanger.findIndex(i => i.id === e) !== -1) {
            prodChanger.splice(prodChanger.indexOf(e), 1);
        }
        products.setCart(prodChanger)


    }

    const Sending = async () => {

        setLoad(true)
        const token = await handleReCaptchaVerify()

        if(!token){
            return
        }
        
        let Arr = products.Cart
        const formData = new FormData()

        formData.set("name", formParams.name)
        formData.set("phone", formParams.phone)
        formData.set("description", formParams.description)
        formData.set("cart", JSON.stringify(Arr))
        try {
            
            sendCart(formData).then(() => {
                products.setCart([])
                setLoad(false)
                setSuccess(true)
                setFormParams({
                    name: "",
                    phone: "",
                    description: "", 
                })
            }).catch((e) => {console.log(e); setLoad(false); setSuccess(false)})
        } catch (error) {
            
        }
        
    }

    function Send(){
        if(formParams.name === ""){
            if(formParams.phone.length < 14){
                if(formParams.description === ""){
                    setError({
                        name: true,
                        phone: true,
                        description:true,
                    })
                }else{
                    setError({
                        name: true,
                        phone: true,
                        description:false,
                    })
                }
            }else{
                if(formParams.description === ""){
                    setError({
                        name: true,
                        phone: false,
                        description:true,
                    })
                }else{
                    setError({
                        name: true,
                        phone: false,
                        description:false,
                    })
                }
            }
        }else{
            if(formParams.phone.length < 14){
                if(formParams.description === ""){
                    setError({
                        name: false,
                        phone: true,
                        description:true,
                    })
                }else{
                    setError({
                        name: false,
                        phone: true,
                        description:false,
                    })
                }
            }else{
                if(formParams.description === ""){
                    setError({
                        name: false,
                        phone: false,
                        description:true,
                    })
                }else{
                    setError({
                        name: false,
                        phone: false,
                        description:false,
                    })
                    Sending()
                   //Sending 
                }
            }
        }
    }

    let prevScrollpos = window.pageYOffset; 
    window.onscroll = location.pathname === "/" && function(){
    let currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
        panel.current.style.bottom = "0";
    } else {
        panel.current.style.bottom = "-80px";
    }
    prevScrollpos = currentScrollPos;
   }

    return(
        <div className="main_wrapper">
            <div ref={panel} className="DeliveryBanner">
                Условия доставки можно прочитать кликнув <button onClick={() => {setOpenDelivery(true); setOpenCart(false)}}>Здесь</button>
            </div>
            <div className="main_header_container">
                
                <Isometric className="main_header_isometric"/>
                <Down className="main_header_down"/>
                <Tops className="main_header_top"/>


                <div className="main_header_buttons_wrapper">
                    <div className="main_logo"><Logo className="main_control_button Logo"/></div>
                    <div className="main_control_buttons">
                        <button className="main_control_button" onClick={() => {OpenCart()}}><Cart className="Cart"/></button>
                        <button className="main_control_button" onClick={() => {setContactOpen(true)}}><Contacts className="Phone"/></button>
                        
                    </div>
                </div> 
                <div className="main_header_text">
                    <h1>Приветствуем Вас!</h1>
                    <p>Мы занимаемся продажей и доставкой одноразовой посуды по всему городу Омску, здесь вы можете оставить заявку для нашего менеджера, который созвонится с Вами в ближайшее время и обсудит дальнейшие детали плодотворного сотрудничества.</p>
                </div>
                <a href="#products">к покупкам!</a>
            </div>
            <div className="main_info_container">
                <Second className="main_info_down"/>
                <SecondRight className="main_info_right"/>
                <Flag className="main_info_flag"/>
                
                <p>Мы на рынке с 2000 года и предоставляем своим оптовым покупателям скидки, также при заказе от 1000 рублей доставка в любую точку города бесплатно, время доставки обговорить можно с менеджером.
                Удачных покупок!</p>
            </div>
            <div id="products" className="main_product_wrapper">
                <div className="main_product_container">
                    {
                        currentPageArray?.map((i, index) => {
                            return(
                                <ProductCard key={index + "product" + i.id} product={i}/>
                            )
                        })
                    }
                </div>
                <div className="pagination">
                    {pageCurrent === 1 ? 
                    <button><EndArrow/></button>
                    :
                    <button style={{transform: "rotate(180deg)"}} onClick={() => setPageCurrent(Number(pageCurrent) - 1)}><Arrow/></button>
                    }
                    
                    <div className="pages_container">
                        {pagesCount.map((i, index) => {
                            return(
                                <button onClick={() => setPageCurrent(i)} key={i + index + "pagination"} className={i === pageCurrent ? "pagination_button Active" : "pagination_button"}>{i}<span></span></button>
                            )
                        })}
                    </div>
                    
                    {pageCurrent === pagesCount.length ? 
                    <button><EndArrow style={{transform: "rotate(180deg)"}}/></button>
                    :
                    <button onClick={() => setPageCurrent(Number(pageCurrent) + 1)}><Arrow/></button>
                    }
                </div>
            </div>
            <div className="main_warning_container">
                <Warning className="main_warning_sign"/>
                <p>Обратите внимание, не все из представленных товаров могут оказаться в наличие!</p>
                <p>После заполнения заявки наши операторы с вами свяжутся.</p>
            </div>
            <div className="main_svg_container">
                <BottomIsometric/>
            </div>
            <Footer/>
            <CartModal open={cartOpen}>
                <div className="modal_background" onClick={() => {setOpenCart(false); setSending(false)}}></div>
                <div className="cart_product_wrapper">
                    <button className="cart_product_cross" onClick={() => {setOpenCart(false); setSending(false)}}><Cross/></button>
                    {
                        success &&
                        <div className="success">Заявка успешно отправлена</div>
                    }
                    
                    {products.Cart.length === 0 ?
                        <div className="cart_product empty" key={"emptyCart"}>
                            <p>Ваша корзина пуста</p>
                            <EmptyCart/>
                        </div>
                        :
                        <>
                            {
                                sending ?
                                <>
                                    <div className="poducts_cart_container sending">
                                        <div className="summary">Итого:{" " + summ + " р"}</div>
                                        <div className="form_inputs_wrapper">
                                            <input className={error.name ? "phone_name error" : "phone_name"} type="text" placeholder="Введите Ваше имя" value={formParams.name} onChange={(e) => setFormParams({...formParams, name: e.target.value})}/>
                                            <InputMask  mask="+7 (___) ___-__-__" replacement={{ _: /\d/ }}  className={error.phone ? "phone_name error" : "phone_name"} placeholder="Номер телефона для связи с вами" value={formParams.phone} onChange={(e) => setFormParams({...formParams, phone: e.target.value})}/>
                                            <textarea className={error.description ? "description error" : "description"} type="text" placeholder="Дополнительная информация для Нашего менеджера" value={formParams.description} onChange={(e) => setFormParams({...formParams, description: e.target.value})}/>
                                        </div>
                                    </div>
                                    {
                                        load ? 
                                        <button className="cart_product_next">Идет отправка</button>
                                        :
                                        <button onClick={() => Send()} className="cart_product_next">ОСТАВИТЬ ЗАЯВКУ</button>
                                    }
                                </>
                                :
                                <>
                                    <div className="poducts_cart_container">
                                    {
                                        products.Cart?.map((i,index) => {
                                            return(
                                                <div className="cart_product full" key={index + i.title}>
                                                    <button className="cart_product_cross_blue" onClick={() => {removeFromCart(i.id)}}><Cross/></button>
                                                    <p className="cart_product_title">{"Товар: " + i.title}</p>
                                                    <p className="cart_product_quantity">{"Количество: " + i.count}</p>
                                                    <p className="cart_product_price">{"Цена: " + i.count * i.price}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                    {
                                        load ? 
                                        <button className="cart_product_next">Идет отправка</button>
                                        :
                                        <button onClick={() => setSending(true)} className="cart_product_next">ОСТАВИТЬ ЗАЯВКУ</button>
                                    }
                                    
                                </>
                            }
                       
                        </>
                    }
                   
                </div>
            </CartModal>
            <ContactModals open={contactOpen}>
                <div className="modal_background" onClick={() => setContactOpen(false)}></div>
                <div className="cart_product_wrapper">
                    <button className="cart_product_cross" onClick={() => setContactOpen(false)}><Cross/></button>
                    <div className="cart_product full">
                        <p className="cart_product_title">Адрес: Серова 28</p>
                        <div className="cart_product_description_modal">
                            <p>Телефон:</p>
                            <a href="tel:+73812453580" className="cart_product_price"> 8 (3812) 45-35-80</a>
                        </div>
                        
                    </div>
                    <div className="cart_product full">
                        <p className="cart_product_title">Адрес: Перелета 18</p>
                        <div className="cart_product_description_modal">
                            <p>Телефон:</p>
                            <a href="tel:+73812727750" className="cart_product_price">8 (3812) 72-77-50,</a>
                            <a href="tel:+79136010147" className="cart_product_price">8 (913) 601-01-47</a>
                        </div>
                    </div>
                </div>
            </ContactModals>

            <CartModal open={openDelivery}>
                <div className="modal_background" onClick={() => setOpenDelivery(false)}></div>
                <div className="cart_product_wrapper">
                    <button className="cart_product_cross" onClick={() => setOpenDelivery(false)}><Cross/></button>
                    <div className="HeaderDelivery">Условия доставки</div>
                    <div className="DescriptionDelivery">
                        <ul>
                            <li>Доставка от 1000 рублей</li>
                            <li>Доставка с понедельника по среду и в пятницу (с 10:00 до 17:00)</li>
                            <li>Доставка в ближайший выездной день после оплаты</li>
                            <li>Есть возможность отсрочки</li>
                            <li>Дополнительно можете обсудить выгодные для Вас условия с нашим менеджером, мы всегда идем навстречу!</li>
                        </ul>
                    </div>
                </div>
            </CartModal>
        </div>
    )
}

export default UserPage