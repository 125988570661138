import "./product_card.css"
import { ReactComponent as Cart} from "../../assets/SmallCart.svg"
import { ReactComponent as Plus} from "../../assets/Plus.svg"
import { ReactComponent as Minus} from "../../assets/Minus.svg"
import { useContext, useState } from "react"
import { Context } from "../.."


const Product_card = ({product}) => {

    const [value, setValue] = useState(product.stepPrice)

    const {products} = useContext(Context)
    
    function Increment(){
        setValue(value + product.stepPrice)
    }

    function Decrement(){
        if(value === product.stepPrice){
            return
        }else{
            setValue(value - product.stepPrice)
        }
    }

    function addCart(){
        const prod = {
            id: product.id,
            title: product.title,
            price: product.price,
            count: value,
        }
        
        if(products?.Cart?.find(i => i.id === prod.id) === undefined){
            products.setCart([...products.Cart, prod])
        }else{
            const prodChanger = products?.Cart.map(i => i.id === prod.id ? prod : i)
            products.setCart(prodChanger)
        }
    }

    return(
        <div className="product_wrapper">
            <img alt="" src={process.env.REACT_APP_URL_API + "/static_backend/" + product.image}></img>
            <div className="product_title">{product.title}</div>
            <div className="product_price">
                <p className="product_price_container">{product.price + " р"}</p>
                <button className="cart_button" onClick={() => addCart()}><Cart/></button>
            </div>
            <div className="product_change_quantity">
                <button onClick={() => Decrement()}><Minus/></button>
                <div className="product_input">{value}</div>
                <button onClick={() => Increment()}><Plus/></button>
            </div>
        </div>
    )
}

export default Product_card