import React from "react";
import {Routes, Route, Navigate} from 'react-router-dom'
import { privateRoutes, publicRoutes} from "./routes";
import { observer } from "mobx-react-lite";
import { MAIN_ROUTE } from "./utils";




const AppRouter = observer(() => {


    return(
        <Routes>
            {publicRoutes.map(({path, component}) =>  
                <Route key={path} path={path} element={component()}/>   
            )}
            {privateRoutes.map(({path, component}) =>  
                <Route key={path} path={path} element={component()}/>   
            )}
           <Route  path="*" element ={<Navigate replace to={MAIN_ROUTE}/>} />
        </Routes>
    )
})

export default AppRouter 