import "./auth_page.css"
import { ReactComponent as Logo} from "../../assets/Logo.svg"
import { useContext, useEffect, useState } from "react"
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import { login } from "../../http/user_api";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_ROUTE, AUTH_ROUTE } from "../../consts/utils";
import { Context } from "../..";

const AuthPage = () => {
    const {products} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const [visibleState, setVisibleState] = useState(false)
    const [formState, setFormState] = useState({
        mail: "",
        password: "",
    })

    const [error, setError] = useState({
        mail: false,
        password: false,
    })

    useEffect(() => {
        if(location.pathname === AUTH_ROUTE){
            if(products.Employ){
                navigate(ADMIN_ROUTE)
            }
        }else{
            return
        }
        
    }, [products.Employ, location.pathname, navigate])

    function auth(){
        if(formState.mail === ""){
            if(formState.password === ""){
                setError({
                    mail: true,
                    password: true,
                })
            }else{
                setError({
                    mail: true,
                    password: false,
                })
            }
        }else{
            if(formState.password === ""){
                setError({
                    mail: false,
                    password: true,
                })
            }else{
                setError({
                    mail: false,
                    password: false,
                })

                loginFunction()
                
            }
        }
    }

    const loginFunction = async () => {
        
        await login(formState).then(() => {
            navigate(ADMIN_ROUTE)
            products.setEmploy(true)
        }).catch((e) => console.log(e))
    }

    return(
        <div className="auth_wrapper">
            <div className="auth_container">
            <div className="auth_logo"><Logo/></div>
                <div className="auth_inputs">
                    <input className={error.mail ? "InputTypePassword error" : "InputTypePassword"} type="text" value={formState.mail} placeholder="Логин" onChange={(e) => setFormState({...formState, mail: e.target.value})}/>
                    <div className="InputTypePasswordWrapper">
                        <input className={error.password ? "InputTypePassword error" : "InputTypePassword"} type={visibleState ? "text" : "password"}
                        value={formState.password}
                        onChange={(e) => setFormState({...formState, password: e.target.value})}
                        placeholder="Пароль"/>
                        <div className="ButtonEye" onClick={() => {setVisibleState(!visibleState)}}>
                            { visibleState ?
                                <FaRegEye/>
                                :
                                <FaRegEyeSlash/>
                            }
                        </div>
                    </div>
                </div>
                <button onClick={() => auth()}>Войти</button>
            </div>
        </div>
    )
}

export default AuthPage