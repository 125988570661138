import "./product_reduct_card.css"
import { useEffect, useState } from "react"
import { ReactComponent as Squere} from "../../assets/SquereAdd.svg"
import { ReactComponent as Cross} from "../../assets/Cross.svg"
import { deleteProduct, updateProduct } from "../../http/product_api"


const ProductReductCard = ({product}) => {


    const[file, setFile] = useState("")
    const[imgPreview, setImgPreview] = useState(null)

    const[info, setterInfo] = useState({
        title: product.title,
        description: product.description, 
        price: product.price,
        stepPrice: product.stepPrice,
    })

    useEffect(() => {
        //Преобразование файла в objectUrl для отображения как картинки, если тип стринг, значит как аттчмент был передан файл, а не картинка
        if(typeof file !== "string"){
            const objectUrl = URL.createObjectURL(file)
            console.log(objectUrl);
            
            setImgPreview(objectUrl)

            return () => URL.revokeObjectURL(objectUrl)
        }
        
    }, [file])

    const onSelectFile = e => {
        if (e.target.files.length === 0) {
            return
        }

        if(e.target.files[0].name.split(".")[1] !== "jpg" && e.target.files[0].name.split(".")[1] !== "png" && e.target.files[0].name.split(".")[1] !== "svg"){
            return
        }else{
            setFile(e.target.files[0])
        }
        
    }

    function Delete(e){
        e.preventDefault()
        deleteProduct(product.id).then().catch(e => console.log(e))
    }

    function Update(){
        const formData = new FormData()
        if(imgPreview === null){
            formData.set("id", product.id)
            formData.set("prevImage", false)
            formData.set("title", info.title)
            formData.set("description", info.description)
            formData.set("price", info.price)
            formData.set("stepPrice", info.stepPrice)
            updateProduct(formData).then(() => alert("yess")).catch(e => console.log(e))
        }else{
            formData.set("id", product.id)
            formData.set("prevImage", product.image)
            formData.set("title", info.title)
            formData.set("description", info.description)
            formData.set("price", info.price)
            formData.set("stepPrice", info.stepPrice)
            formData.set("image", file)
            updateProduct(formData).then(() => alert("yess")).catch(e => console.log(e))
        }
    }


    return(
        <form className="product_wrapper_add">
            <button className="product_wrapper_add_cross" onClick={(e) => Delete(e)}><Cross/></button>
            {imgPreview !== null ? 
                <img alt="" src={imgPreview}/>
                :
                <>
                    {product.image === null || product.image === undefined ?
                        <Squere/>
                        :
                        <img alt="" src={process.env.REACT_APP_URL_API + "/static_backend/" + product.image}/>
                    }
                </>
                
            }
            
            <input className="img_input" type="file" onChange={(e) => {onSelectFile(e)}} required/>
            <input required className="product_title" placeholder="название товара" value={info.title} onChange={(e) => setterInfo({...product, title: e.target.value})}/>
            <div className="product_price_add">
                <input required className="product_price_container" type="number" placeholder="цена" value={info.price} onChange={(e) => setterInfo({...product, price: e.target.value})}/>
            </div>
            <div className="product_change_quantity">
                <input required className="product_input" type="number" placeholder="шаг количества товара" value={info.stepPrice} onChange={(e) => setterInfo({...product, stepPrice: e.target.value})}/>
            </div>
            <button type="submit" className="cart_button_reduct" onClick={() => {Update()}}>Обновить</button>
        </form>
    )
}

export default ProductReductCard