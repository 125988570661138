import "./product_reduct_card.css"
import { useEffect, useState } from "react"
import { ReactComponent as Squere} from "../../assets/SquereAdd.svg"


const ProductAddCard = ({product, name, functionName, setter, setterInfo}) => {


    const[file, setFile] = useState("")
    const[imgPreview, setImgPreview] = useState(null)

    useEffect(() => {
        //Преобразование файла в objectUrl для отображения как картинки, если тип стринг, значит как аттчмент был передан файл, а не картинка
        if(typeof file !== "string"){
            const objectUrl = URL.createObjectURL(file)
            console.log(objectUrl);
            
            setImgPreview(objectUrl)

            return () => URL.revokeObjectURL(objectUrl)
        }
        
    }, [file])

    const onSelectFile = e => {
        if (e.target.files.length === 0) {
            return
        }

        if(e.target.files[0].name.split(".")[1] !== "jpg" && e.target.files[0].name.split(".")[1] !== "png" && e.target.files[0].name.split(".")[1] !== "svg"){
            return
        }else{
            setFile(e.target.files[0])
            setter(e.target.files[0])
        }
        
    }


    return(
        <form className="product_wrapper_add">
            {imgPreview !== null ? 
                <img alt="" src={imgPreview}/>
                :
                <Squere/>
            }
            
            <input className="img_input" type="file" onChange={(e) => {onSelectFile(e)}} required/>
            <input required className="product_title" placeholder="название товара" value={product.title} onChange={(e) => setterInfo({...product, title: e.target.value})}/>
            <div className="product_price_add">
                <input required className="product_price_container" type="number" placeholder="цена" value={product.price} onChange={(e) => setterInfo({...product, price: e.target.value})}/>
            </div>
            <div className="product_change_quantity">
                <input required className="product_input" type="number" placeholder="шаг количества товара" value={product.stepPrice} onChange={(e) => setterInfo({...product, stepPrice: e.target.value})}/>
            </div>
            <button type="submit" className="cart_button_reduct" onClick={() => {functionName()}}>{name}</button>
        </form>
    )
}

export default ProductAddCard